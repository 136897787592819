<template>
  <svg fill="none"
       xmlns="http://www.w3.org/2000/svg"
       viewBox="0 0 295 350"
       ref="container"
  >
    <path class="curve"
          ref="curve"
          d="M9.62 112.32S70.48-20.02 133 16.32c36 20.93 44.32 67 41.2 105.32-1.47 18.07-4.78 37.76-13.25 53.94-6.91 13.19-19 24.55-33.72 26.69-18.07 2.63-36.31-9-45.74-23.83-20.18-31.76 17.6-71.16 39.46-91.3 25.66-23.66 63.28-48.44 100-39 66.59 17.17 73.89 115.46 54.83 167.67-15.89 43.56-56.06 93.45-107.18 90.94"
          stroke="currentColor"
          stroke-width="20"
          stroke-miterlimit="10"
          pathLength="1"
    />
    <path class="arrow"
          ref="arrow"
          d="m192.79 344.06-34.71-39.79 39.79-34.7"
          stroke="currentColor"
          stroke-width="15.78"
          stroke-miterlimit="10"
          pathLength="1"
    /></svg>
</template>

<script setup lang="ts">
import { inView, timeline } from 'motion';
import { onMounted, ref } from 'vue';

type SvgShapeArrowCurvedProps = {
  animate?: boolean
}

withDefaults(defineProps<SvgShapeArrowCurvedProps>(), {
  animate: true
});

const container = ref(null);
const arrow = ref(null);
const curve = ref(null);

const draw = (progress:number) => ({
  // This property makes the line "draw" in when animated
  strokeDashoffset: 1 - progress,
  visibility: 'visible'
});

onMounted(() => {
  inView(container.value, () => {
    timeline([
      [curve.value, draw(1), { duration: 2, delay: 0, easing: 'linear' }],
      [arrow.value, draw(1), { duration: 0.6, delay: 0.15, easing: 'ease-out' }]
    ]);
  }, { amount: 0.2 });
});
</script>

<style scoped>
circle,
path.curve {
  fill: transparent;
  stroke: currentColor;
  stroke-width: 20px;
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  visibility: hidden;
}
path.arrow {
  fill: transparent;
  stroke: currentColor;
  stroke-width: 15px;
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  visibility: hidden;
}
</style>