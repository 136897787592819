<template>
  <div class="relative flex justify-center overflow-hidden pt-[8vw] md:pt-0 pb-[10vw] md:pb-[7vw]">
    <SvgShapeCloud class="absolute -bottom-[50vw] md:-bottom-[32vw] w-[180vw] md:w-[110vw] text-pink -left-[40vw] md:-left-[0.75vw]" />
    <SvgShapeArrowCurved class="new-games absolute top-[14vw] md:top-[10vw] w-[45vw] md:w-[12vw] text-yellow -rotate-[50deg] -left-[26vw] md:-left-[1vw]" />
    <SvgShapeDiamond class="absolute hidden md:block w-[16.25vw] md:w-[6vw] text-plum left-[8vw] md:left-[10vw] top-[108vw] md:top-[85vw] -rotate-[35deg]" />
    <SvgShapeGearSharp class="absolute top-[1vw] md:top-[18.5vw] -right-[12vw] md:right-[2vw] w-[31vw] md:w-[13vw] text-yellow" />

    <NuxtImg format="auto"
             src="https://cdn-ttf.o7web.com/assets/public/img/videos/angela_tom.png"
             class="hidden md:block relative md:h-[43.25vw]"
             loading="lazy"
             alt="angela tom"
             sizes="xs:88vw sm:88vw md:85vw lg:85vw xl:85vw xxl:85vw"
    />

    <NuxtImg format="auto"
             src="https://cdn-ttf.o7web.com/assets/public/img/videos/angela_tom_mobile.png"
             class="md:hidden relative h-[124vw]"
             loading="lazy"
             alt="angela tom"
             sizes="xs:88vw sm:88vw md:85vw lg:85vw xl:85vw xxl:85vw"
    />
  </div>
</template>
