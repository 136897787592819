<template>
  <div class="relative flex justify-center overflow-hidden pt-[2vw] pb-[9vw] md:pb-[3vw]">
    <SvgShapeCloud class="absolute -bottom-[50vw] md:-bottom-[32vw] w-[180vw] md:w-[110vw] text-turquoise -left-[40vw] md:-left-[0.75vw]" />

    <SvgShapeArrowCurved class="absolute top-[64vw] md:top-[2vw] w-[45vw] md:w-[12vw] text-yellow -rotate-[50deg] -left-[32vw] md:-left-[1vw]" />

    <SvgShapeDiamond class="absolute w-[16.25vw] md:w-[8.75vw] text-blue left-[8vw] md:left-[10vw] top-[132vw] md:top-[22vw]"
                     :rotate="-35"
    />
    <SvgShapeGearSharp class="absolute top-[13vw] md:top-[5.5vw] -right-[34vw] md:right-[10vw] w-[55vw] md:w-[13vw] text-lemon" />

    <NuxtImg format="auto"
             src="https://cdn-ttf.o7web.com/assets/public/img/games/hank.png"
             class="hidden md:block relative md:h-[36.5vw]"
             alt="hank"
             loading="lazy"
             sizes="xs:86vw sm:86vw md:60vw lg:60vw xl:60vw xxl:60vw"
    />

    <NuxtImg format="auto"
             src="https://cdn-ttf.o7web.com/assets/public/img/games/hank_mobile.png"
             class="md:hidden relative h-[172vw]"
             alt="hank"
             loading="lazy"
             sizes="xs:86vw sm:86vw md:60vw lg:60vw xl:60vw xxl:60vw"
    />
  </div>
</template>
